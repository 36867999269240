import * as React from "react";
import HeroSmall from "../components/hero-small";
import Footer from "../components/footer";
import drops from "../images/drops.jpg";

const heading = "Disclaimer";

// markup
const DisclaimerPage = () => {
  return (
    <main className="font-light overflow-hidden">
      <HeroSmall />

      <div className="relative z-20">
        <section id="maakkennis" className="bg-white">
          <div className="max-w-7xl mx-auto p-10 sm:py-24">
            <h1 className="mb-8 text-2xl sm:text-3xl font-medium">{heading}</h1>
            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              Copyright
            </h2>
            <p>
              De inhoud van deze site, de daarin opgenomen gegevens,
              afbeeldingen, geluiden, teksten en combinaties daarvan en de
              programmatuur zijn beschermd door auteurs- en databankrechten.
              Deze rechten berusten bij Marja Bron – communicatie (hierna ook
              ‘Marja Bron’). Zonder schriftelijke voorafgaande toestemming van
              Marja Bron is het niet toegestaan deze site of enig onderdeel
              daarvan te kopiëren.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">Merken</h2>
            <p>
              Alle woord- en beeldmerken op deze site zijn eigendom van Marja
              Bron, tenzij anders aangegeven. Zonder schriftelijke toestemming
              is het niet toegestaan gebruik te maken van deze merken.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              Aansprakelijkheid
            </h2>
            <p>
              Ondanks de voortdurende zorg en aandacht die wordt besteed aan de
              samenstelling van deze site en de daarin opgenomen gegevens, kan
              Marja Bron niet instaan voor de volledigheid, juistheid of
              voortdurende actualiteit van de gegevens. Marja Bron aanvaardt
              geen aansprakelijkheid voor enigerlei directe of indirecte schade,
              van welke aard dan ook, die voortvloeit uit of in enig opzicht
              verband houdt met het gebruik van de site, (on)bereikbaarheid van
              de site of ter beschikking gestelde programmatuur.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              Informatie van derden
            </h2>
            <p>
              De op deze site geplaatste informatie die van derden afkomstig is,
              geeft uitsluitend de persoonlijke mening weer van de betreffende
              inzender. Marja Bron is op geen enkele wijze verantwoordelijk voor
              de inhoud hiervan, noch voor de toegang tot of de informatie op
              enige site die is gelinkt van of naar deze site.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">Frames</h2>
            <p>Het is niet toegestaan deze site te framen.</p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              E-maildisclaimer
            </h2>
            <p>
              De informatie, verzonden in of met ons e-mail bericht is
              uitsluitend bestemd voor geadresseerde. Gebruik van deze
              informatie door anderen dan geadresseerde is verboden.
              Openbaarmaking, vermenigvuldiging, verspreiding en/of verstrekking
              van deze informatie aan derden is niet toegestaan. Marja Bron
              staat niet in voor de juiste en volledige overbrenging van de
              inhoud van een verzonden e-mail, noch voor een tijdige ontvangst
              daarvan. In geen geval is Marja Bron aansprakelijk voor enige
              schade, van welke aard ook, welke het directe of indirecte gevolg
              is van het versturen van deze e-mail. Aan de ontvangst en inhoud
              van dit e-mail bericht kunnen geen rechten worden ontleend.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              Privacy &amp; cookies
            </h2>
            <p>
              Marja Bron respecteert de privacy van alle gebruikers van haar
              website en draagt er zorg voor dat de persoonlijke informatie die
              u ons verschaft vertrouwelijk wordt behandeld. Marja Bron zal uw
              gegevens niet verkopen aan derden.
            </p>

            <p>
              Wij maken op deze website gebruik van cookies om u de beste
              gebruikerservaring te geven. Een cookie is een eenvoudig klein
              bestandje dat met de pagina’s van deze website wordt meegestuurd
              en door uw browser op uw harde schijf wordt opgeslagen. Als u
              liever niet heeft dat er cookies worden geplaatst op uw computer,
              kunt u het gebruik van cookies blokkeren. U dient dit per computer
              en per browser handmatig in te stellen via de instellingen van de
              browser. Standaard staat bij iedere browser ingesteld dat u
              cookies accepteert. Ook is het mogelijk om een zogenaamde plug-in
              te installeren in uw browser, zodat u eenvoudig per website de
              cookies kunt blokkeren. Aangezien iedere browser anders is,
              verwijzen wij u naar de help-functie van uw browser om de
              instellingen aan te passen.
            </p>

            <h2 className="mt-8 mb-2 text-lg sm:text-xl font-medium">
              Welke cookies gebruiken wij?
            </h2>
            <p>
              Marja Bron maakt gebruik van cookies van Google om statistieken te
              verzamelen over het gebruik van onze website. Hiermee kunnen we de
              website vervolgens verbeteren voor gemak. De informatie die Google
              verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt
              nadrukkelijk niet meegegeven. Voor meer informatie over de wijze
              waarop Google gebruik maakt van cookies, verwijzen we u naar deze
              website: http://www.google.nl/policies/privacy/ads/
            </p>
          </div>
        </section>

        {/* sfeer */}
        <div
          className="relative min-h-[300px] bg-fixed bg-cover flex flex-col gap-14 text-white"
          style={{ backgroundImage: `url(${drops})` }}
        ></div>

        <Footer />
      </div>
    </main>
  );
};

export default DisclaimerPage;
