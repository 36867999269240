import React, { useState, useEffect } from "react";

import logo from "../images/logo.svg";
import logoDia from "../images/logo-dia.svg";
import leaves from "../images/leaves.jpg";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const navigation = [
  {
    name: "Maak kennis",
    href: "/#maakkennis",
  },
  {
    name: "Mijn ervaring",
    href: "/#ervaring",
  },
  {
    name: "Opdrachtgevers",
    href: "/#opdrachtgevers",
  },
  {
    name: "Contact",
    href: "/#contact",
  },
];

const Hero = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header
        className={`fixed z-50 w-full transition-colors ${
          scrollPosition > 10
            ? "bg-white bg-opacity-95 backdrop-blur-sm shadow-xl group-fixed"
            : ""
        }`}
      >
        <Popover className="relative">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img
                  className="h-24 w-auto sm:h-28"
                  src={scrollPosition > 10 ? logo : logoDia}
                  alt="Marja Bron Communicatie"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white bg-opacity-20 rounded-md p-2 inline-flex items-center justify-center text-earth hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-earth">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <nav className="hidden md:flex space-x-10">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={`text-base font-medium ${
                    scrollPosition > 10 ? "text-black" : "text-white"
                  } hover:underline group-fixed:text-black`}
                >
                  {item.name}
                </a>
              ))}
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white ">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-24 w-auto"
                        src={logo}
                        alt="Marja Bron Communicatie"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-black focus:outline-none ">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 gap-7">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                        >
                          <div className="ml-4 text-base font-medium text-gray-900">
                            {item.name}
                          </div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
      <section
        className="relative min-h-[160px] overflow-hidden bg-fixed bg-cover flex items-center justify-center"
        style={{ backgroundImage: `url(${leaves})` }}
      ></section>
    </div>
  );
};

export default Hero;
